<template>
  <stripe-loader class="card-form">
    <div v-if="intentLoaded">
      <!-- Discount Banner -->
      <div class="card-form__banner text-center text-sm bg-orange-100 rounded-t-lg mx-30" v-if="showForm && bannerText !== null">
        {{ bannerText }}
      </div>
      <div class="card-form__inner justify-content content-center flex flex-wrap mx-10" v-if="showForm">
        <!-- STRIPE ELEMENTS -->
        <div class="payment-simple w-full">
          <StripeElements :stripe-key="pk_key" :instance-options="instanceOptions" :elements-options="elementsOptions" #default="{ elements }" ref="elms">
            <StripeElement type="payment" :elements="elements" @ready="isLoaded" ref="payment" class="md:w-11/12 md:mx-auto" />
          </StripeElements>
        </div>
        <button v-if="elementsLoaded" :class="['card-form__button', 'main-cta', { disabled: isUpdating }]" @click="updateCard">
          <span v-if="isUpdating"><Loader /> Updating</span>
          <span v-else>{{ updateButtonLabel }}</span>
        </button>
      </div>
      <!-- Update Failed Message -->
      <div class="card-form__inner payment-message text-center" v-if="cardUpdateFailed">
        <div>
          <p class="text-xl text-brand-black font-semibold mb-8">{{ message.header }}</p>
          <p class="text-lg text-brand-black mt-4">{{ message.description }}</p>
        </div>
        <button class="card-form__button mt-15 main-cta" @click="retry" type="button">Try Again</button>
      </div>
      <!-- Update Success Message -->
      <div class="card-form__inner payment-message text-center" v-if="cardUpdateSuccess">
        <div>
          <p class="text-xl text-brand-black font-semibold mb-8">{{ message.header }}</p>
          <p class="text-lg text-brand-black mt-4">{{ message.description }}</p>
        </div>
        <button class="card-form__button mt-15" @click="redirect" type="button">Continue to {{ companyName }}</button>
      </div>
      <!-- Append watermark: IF cardUpdateSuccess & org has watermarks enabled -->
      <div v-if="showWatermark" class="mt-4">
        <Watermark message="Subscription Verified by Churnkey" product="pr" />
      </div>

      <!-- Invoice is Already Paid -->
      <div class="card-form__inner payment-message text-center" v-if="alreadyPaid && !cardUpdateComplete">
        <div>
          <p class="text-xl text-brand-black font-semibold mb-8 pt-10">Your invoice has already been paid.</p>
        </div>
        <button class="card-form__button mt-15 main-cta" @click="redirect" type="button">Continue to {{ companyName }}</button>
      </div>
      <!-- Invoice is Already Void -->
      <div class="card-form__inner payment-message text-center" v-else-if="alreadyVoid && !cardUpdateComplete">
        <div>
          <p class="text-xl text-brand-black font-semibold mb-8 pt-10">Your invoice has been void, and can no longer be paid.</p>
        </div>
        <button class="card-form__button mt-15 main-cta" @click="redirect" type="button">Continue to {{ companyName }}</button>
      </div>
      <!-- Invoice is Already Uncollectible -->
      <div class="card-form__inner payment-message text-center" v-else-if="alreadyUncollectible && treatUncollectibleAsVoid && !cardUpdateComplete">
        <div>
          <p class="text-xl text-brand-black font-semibold mb-8 pt-10">Your invoice has been marked uncollectible, and can no longer be paid.</p>
        </div>
        <button class="card-form__button mt-15 main-cta" @click="redirect" type="button">Continue to {{ companyName }}</button>
      </div>
      <!-- Invoice past collection period -->
      <div class="card-form__inner payment-message text-center" v-else-if="pastCollectionPeriod && !cardUpdateComplete">
        <div>
          <p class="text-xl text-brand-black font-semibold mb-8 pt-10">Your invoice is past the collection period, and can no longer be paid.</p>
        </div>
        <button class="card-form__button mt-15 main-cta" @click="redirect" type="button">Continue to {{ companyName }}</button>
      </div>
    </div>
  </stripe-loader>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus';
import StripeLoader from '@/components/StripeLoader.vue';
import Loader from '@/components/ButtonLoader.vue';
import Watermark from '@/components/Watermark.vue';

import appearance from '@/helpers/elements-config';
import * as Filters from '@/helpers/filters';
import EventBus from '@/helpers/event-bus';
import OrgService from '@/api/org.service';
import router from '@/router';

export default {
  name: 'StripeForm',
  components: {
    StripeElements,
    StripeElement,
    StripeLoader,
    Loader,
    Watermark,
  },
  computed: {
    updateButtonLabel() {
      if (this.$store.state.account.ctaText !== undefined && this.$store.state.account.ctaText !== '') {
        return this.$store.state.account.ctaText;
      }
      const offerCount = this.offers.length;
      if (offerCount === 0) return 'Update Card';
      if (offerCount === 1) return 'Update Card & Redeem Offer';
      return 'Update Card & Redeem Offers';
    },
    bannerText() {
      if (this.futureDiscount && this.invoiceDiscount) {
        return 'Discounts applied to current and future payments';
      }
      if (this.futureDiscount) {
        const { coupon } = this.futureDiscount.displayDiscount;
        const showDuration = Filters.formatCouponDuration(coupon, 'for');
        const showAmount = Filters.formatCouponAmount(coupon);
        return `${showAmount} discount applied ${showDuration}`;
      }
      if (this.invoiceDiscount) {
        const { coupon } = this.invoiceDiscount.displayDiscount;
        const showAmount = Filters.formatCouponAmount(coupon);
        return `${showAmount} discount applied to your outstanding invoice`;
      }
      return null;
    },
    dunningInvoice() {
      const dunningInvoiceId = this.$store.state.account.dunning.invoice.invoiceId;
      const dunningInvoice = this.$store.state.account.invoices.find((invoice) => invoice.invoice === dunningInvoiceId);
      return dunningInvoice || this.$store.state.account.dunning.invoice;
    },
    alreadyPaid() {
      return this.dunningInvoice.status === 'paid';
    },
    alreadyVoid() {
      return this.dunningInvoice.status === 'void';
    },
    alreadyUncollectible() {
      return this.dunningInvoice.status === 'uncollectible';
    },
    treatUncollectibleAsVoid() {
      return ['acct_1GXbpLEWcGtkD3za'].includes(this.$store.state.account.stripeAccountId);
    },
    pastCollectionPeriod() {
      const cutoff = this.$store.state.account?.providerSettings?.pastCollectionPeriod || 30;
      if (this.dunningInvoice) {
        const invoiceDate = new Date(this.dunningInvoice.created);
        // check if within 30 days of today
        const today = new Date();
        const thirtyDaysAgo = new Date(today.setDate(today.getDate() - cutoff));
        return invoiceDate < thirtyDaysAgo;
      }
      return false;
    },
    showForm() {
      // return !this.cardUpdateFailed && !this.cardUpdateComplete && !this.alreadyPaid && !this.alreadyVoid && !this.alreadyUncollectible;
      return (
        !this.cardUpdateFailed &&
        !this.cardUpdateComplete &&
        !this.alreadyPaid &&
        !this.alreadyVoid &&
        !this.pastCollectionPeriod &&
        !(this.alreadyUncollectible && this.treatUncollectibleAsVoid)
      );
    },
    cardUpdateSuccess() {
      return this.cardUpdateComplete && !this.cardUpdateFailed;
    },
    showWatermark() {
      return this.$store.state.account.branding?.showWatermark && this.cardUpdateSuccess;
    },
    companyName() {
      return this.$store.state.account.orgName;
    },
    offers() {
      return this.$store.state.account.offers;
    },
    futureDiscount() {
      if (!this.offers || this.offers.length === 0) return null;
      return this.offers.find((o) => o.offerType === 'DISCOUNT');
    },
    invoiceDiscount() {
      if (!this.offers || this.offers.length === 0) return null;
      return this.offers.find((o) => o.offerType === 'INVOICE_DISCOUNT');
    },
    redirectLink() {
      let link = this.$store.state.account?.dunning?.checkoutPage?.redirectLink;
      // prepend https:// if not already there
      if (link && link.indexOf('://') === -1) {
        link = `https://${link}`;
      }
      return link;
    },
  },
  data() {
    const testMode = this.$store.state.account.mode === 'test';
    const stripeTestKey = process.env.VUE_APP_STRIPE_TEST_KEY;
    const stripeLiveKey = process.env.VUE_APP_STRIPE_KEY;
    const pkKey = testMode ? stripeTestKey : stripeLiveKey;
    return {
      stripeLoaded: false,
      intentLoaded: false,
      elementsLoaded: false,
      cardUpdateComplete: false,
      cardUpdateFailed: false,
      isUpdating: false,
      message: {
        header: '',
        description: '',
      },
      pk_key: pkKey,
      instanceOptions: { locale: 'auto', stripeAccount: null },
      elementsOptions: { locale: 'auto', loader: 'auto', appearance },
    };
  },

  mounted() {
    // Load Data for Card Form
    this.instanceOptions.stripeAccount = this.$store.state.account.stripeAccountId;
    this.getSetupIntent();
  },

  methods: {
    async retry() {
      // Adding the component back in
      this.cardUpdateFailed = false;

      // Set up again:
      this.intentLoaded = false;
      await this.getSetupIntent();
    },
    redirect() {
      // navigate to the org's website
      window.location.href = this.redirectLink;
    },
    isLoaded() {
      this.elementsLoaded = true;
    },

    // Configures Stripe by setting up the elements and creating the card element.
    async getSetupIntent() {
      try {
        const data = await OrgService.getSetupIntent();
        this.elementsOptions.clientSecret = data.client_secret;
        this.intentLoaded = true;
      } catch (err) {
        console.log('Error on getSetupIntent:', err.response?.data);
        this.$store.commit('SET_ERROR', { errorMessage: err.response?.data });
        // router.push('/error');
      }
    },

    async updateCard() {
      if (this.isUpdating) return; // Disable button while loading!
      this.isUpdating = true;
      const { elements, instance } = this.$refs.elms;

      /*
      const previewMode = true;
      if (previewMode) {
        console.log('PREVIEW MODE: No card update or invoice payment will be processed.');
        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.cardUpdateComplete = true;
        this.message.header = `You're all good.`;
        this.message.description = `We were able to charge your card on file. There's nothing you need to do for your account at this time.`;
        EventBus.$emit('invoicePaid');
      }
      */

      try {
        const response = await instance.confirmSetup({ elements, redirect: 'if_required' });
        if (response.error) {
          // Inform the customer that there was an error.
          this.cardUpdateFailed = true;
          this.message.header = response.error.message || 'There was an error processing your payment.';
          this.message.description = 'Please double check your payment method and try again.';
        }
        if (response.setupIntent && response.setupIntent.status === 'succeeded') {
          // Set new payment method as default and charge for outstanding invoice amount
          try {
            const data = await OrgService.chargeBalanceToCard({ paymentMethodId: response.setupIntent.payment_method });
            console.log('data', data);
            this.cardUpdateComplete = true;
            this.message.header = `You're all good.`;
            this.message.description = `We were able to charge your card on file. There's nothing you need to do for your account at this time.`;
            if (data.status && data.status === 'paid') {
              EventBus.$emit('invoicePaid', data);
            }
          } catch (error) {
            EventBus.$emit('logIssue', this.$store.state.account);
            console.log('Error updating card and paying invoice', error);
            this.cardUpdateFailed = true;
            this.message.header = response.error?.message || 'There was an error processing your payment.';
            this.message.description = 'Please double check your payment method and try again.';
          }
        }
      } catch (error) {
        EventBus.$emit('logIssue', this.$store.state.account);
        console.log('error', error);
      } finally {
        this.isUpdating = false;
      }
    },
  },
  filters: {
    formatCouponDuration: Filters.formatCouponDuration,
  },
};
</script>
<style scoped>
@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .card-form__inner {
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .card-form__button {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
.card-form__inner {
  padding-top: 40px;
  min-height: 350px;
}

.payment-message {
  padding-top: 40px;
  min-height: 300px;
}

@media only screen and (max-width: 480px) {
  .card-form__inner {
    padding-top: 0px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    min-height: 350px;
  }
}
</style>

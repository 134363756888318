<template>
  <div>
    <PreviewBanner v-if="$store.getters.preview">
      <span v-if="$store.getters.mode === 'test'">You're viewing a test invoice.</span>
      <span v-else>This is a live invoice, so please proceed with care.</span>
    </PreviewBanner>
    <div class="flex flex-col md:flex-row">
      <!-- left section -->
      <div class="w-screen justify-center md:content-center md:h-screen md:w-1/2 sm:content-start bg-white flex flex-wrap">
        <div class="mx-auto w-full max-w-screen-sm md:w-full sm:w-3/4 lg:p-14 md:p-8 p-6 bg-white">
          <div
            v-if="existingDiscount && !alreadyPaid"
            class="p-4 mb-8 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
            role="alert"
          >
            Note: You currently have a
            <b>{{ existingDiscount.coupon | formatCouponAmount }} off {{ existingDiscount.coupon | formatCouponDuration('for') }}</b> discount<span
              v-if="existingDiscount.end"
            >
              valid until {{ existingDiscount.end | formatDate }}</span
            >. If you don't update your card, you will lose this discount.
          </div>
          <div
            v-if="existingDiscount && alreadyPaid"
            class="p-4 mb-8 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
            role="alert"
          >
            You have a <b>{{ existingDiscount.coupon | formatCouponAmount }} off {{ existingDiscount.coupon | formatCouponDuration('for') }}</b> discount<span
              v-if="existingDiscount.end"
            >
              valid until {{ existingDiscount.end | formatDate }}</span
            >.
          </div>
          <!-- img v-if="companyLogo" :src="companyLogo" class="mb-10 w-36 mx-auto md:mx-0 mt-4 md:mt-0" /> -->
          <BrandImage :src="companyLogo" alignment="left" v-if="companyLogo" class="mb-10 mt-4 md:mt-0" />
          <h1 v-else-if="companyName" class="text-xl text-center md:text-left text-brand-black font-semibold mb-6">{{ companyName }}</h1>
          <p class="text-xl text-brand-medium-brown leading-relaxed mb-8 text-center md:text-left">
            {{ $store.getters.updateCardDescription }}
            <a :href="redirectLink" target="_blank" class="cursor-pointer underline underline-offset-8">{{ companyName }}</a
            >.
          </p>
          <div class="space-y-2 divide-y divide-brand-sand">
            <p class="text-sm text-brand-black mb-2 font-medium text-center md:text-left">{{ customerName }}'s {{ customerPlan }}</p>
            <!-- Invoice Items -->
            <div class="flex items-center justify-between py-4" v-for="bill in invoices" :key="bill.invoice">
              <div class="flex align-items-center">
                <div v-if="bill.displayDiscount" class="bg-red w-16 bg-orange-100 leading-10 text-center align-middle rounded-md text-gray-900 mr-2">
                  <span>-{{ bill.displayDiscount.coupon | formatCouponAmount }}</span>
                </div>
                <div class="bg-white">
                  <span>
                    <span v-if="bill.displayDiscount" class="text-brand-medium-brown font-medium mr-1 line-through">{{
                      bill | amountBeforeDiscount | formatAmount(bill.currency)
                    }}</span>
                    <span class="text-brand-black font-semibold">{{ bill | amountAfterDiscount | formatAmount(bill.currency) }}</span>
                  </span>
                  <p class="text-xs text-brand-medium-brown font-medium">
                    <!-- Future invoice preview with discount -->
                    <span v-if="bill.status === 'draft' && bill.displayDiscount">
                      <!-- Forever future discount -->
                      <span v-if="bill.displayDiscount.coupon.couponDuration === null"
                        >Starting {{ bill.periodEnd | formatDate }} discount applies forever</span
                      >
                      <!-- Temporary future discount -->
                      <span v-else>
                        {{ bill.periodEnd | formatDate }}
                        {{ bill.displayDiscount.coupon | formatCouponDuration('x') }}
                      </span>
                    </span>
                    <span v-else>
                      <!-- Regular date display -->
                      {{ bill.created | formatDate }}
                    </span>
                  </p>
                </div>
              </div>
              <span v-if="bill.status === 'paid'" class="bg-green-100 rounded-3xl font-semibold text-sm px-4 py-1 text-green-900">Paid</span>
              <span v-else-if="bill.status === 'uncollectible'" class="bg-red-100 rounded-3xl font-semibold text-sm px-4 py-1 text-red-900">Past Due</span>
              <span v-else-if="bill.status === 'draft' && !bill.invoice" class="bg-fuschia-200 rounded-3xl font-semibold text-sm px-4 py-1 text-fuschia-900"
                >Future Offer</span
              >
              <span v-else class="bg-brand-sand rounded-3xl font-semibold text-sm px-4 py-1 text-brand-brown">Failed</span>
            </div>
          </div>
          <div v-if="showWatermark" class="mt-6 flex justify-start">
            <Watermark message="Verified by Churnkey" product="pr" align="left" />
          </div>
        </div>
      </div>
      <!-- right section -->
      <div class="h-screen md:w-1/2 md:bg-brand-light-sand bg-neutral-800 justify-center md:content-center xs:content-start flex flex-wrap xs:bg-white">
        <StripeForm class="px-0 pt-0 md:bg-brand-light-sand xs:bg-white" />
      </div>
    </div>
  </div>
</template>

<script>
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import EventBus from '@/helpers/event-bus';
import * as Filters from '@/helpers/filters';
import StripeForm from '@/components/StripeForm.vue';
import Watermark from '@/components/Watermark.vue';
import { getIntegerCurrencyString } from '@/helpers/currency-utils';
import { getInvoiceDescription } from '@/helpers/subscription-utils';
import PreviewBanner from '@/components/PreviewBanner.vue';
import BrandImage from '@/components/BrandImage.vue';

dayjs.extend(relativeTime);

export default {
  name: 'home',
  components: {
    StripeForm,
    Watermark,
    PreviewBanner,
    BrandImage,
  },
  data() {
    return {};
  },
  mounted() {
    const vm = this;
    this.$i18n.locale = navigator.language;
    EventBus.$on('alert', (title, msg) => vm.launchAlert(title, msg));
    EventBus.$on('invoicePaid', (invoice) => vm.markInvoicePaid(invoice));
  },
  computed: {
    account() {
      return this.$store.state?.account;
    },
    customerName() {
      return this.account?.name || this.account?.email;
    },
    invoices() {
      // map these to handle payment status and amount due
      return this.account?.invoices;
    },
    dunningInvoice() {
      const dunningInvoiceId = this.account.dunning.invoice.invoiceId;
      const dunningInvoice = this.invoices.find((invoice) => invoice.invoice === dunningInvoiceId);
      return dunningInvoice || this.account.dunning.invoice;
    },
    alreadyPaid() {
      return this.dunningInvoice.status === 'paid';
    },
    existingDiscount() {
      return this.account?.existingDiscount;
    },
    customerPlan() {
      if (this.invoices) {
        return getInvoiceDescription(this.invoices[0]);
      }
      return null;
    },
    futureDiscount() {
      if (!this.account.offers || this.account.offers.length === 0) return null;
      return this.account.offers.find((o) => o.offerType === 'DISCOUNT');
    },
    invoiceDiscount() {
      if (!this.account.offers || this.account.offers.length === 0) return null;
      return this.account.offers.find((o) => o.offerType === 'INVOICE_DISCOUNT');
    },
    companyName() {
      return this.account?.orgName;
    },
    companyLogo() {
      return this.account.branding?.brandImage;
    },
    redirectLink() {
      let link = this.account?.dunning?.checkoutPage?.redirectLink;
      // prepend https:// if not already there
      if (link && link.indexOf('://') === -1) {
        link = `https://${link}`;
      }
      return link;
    },
    showWatermark() {
      return this.account?.branding?.showWatermark;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    errorContext() {
      return this.$store.state.account;
    },
  },
  filters: {
    formatDate(date) {
      if (date !== null) {
        return dayjs(date).format('MMMM D, YYYY');
      }
      return '';
    },
    formatAmount(amount, currency) {
      return getIntegerCurrencyString(amount, currency, false);
      // return `$${(amount / 100).toFixed(2)}`;
    },
    amountBeforeDiscount(invoice) {
      if (invoice.displayDiscount !== undefined) {
        return invoice.displayDiscount.amountBeforeDiscount;
      }
      return '';
    },
    amountAfterDiscount(invoice) {
      if (invoice.displayDiscount !== undefined) {
        return invoice.displayDiscount.amountAfterDiscount;
      }
      if (invoice.status === 'paid') return invoice.amountPaid;
      return invoice.amountRemaining;
    },
    formatCouponDuration: Filters.formatCouponDuration,
    formatCouponAmount: Filters.formatCouponAmount,
  },
  methods: {
    markInvoicePaid(invoice) {
      const invoiceIndex = this.invoices.findIndex((i) => i.invoice === invoice.invoice);
      this.$set(this.invoices, invoiceIndex, {
        ...this.invoices[invoiceIndex],
        status: invoice.status,
        amountPaid: invoice.amountPaid,
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/style.scss';
</style>
